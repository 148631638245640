import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Grid,
  theme,
  Flex,
  Heading,
  Spacer,
  Avatar,
  Link,
  HStack,
  Stack,
  SkeletonText,
  useMediaQuery,
  Icon,
  IconButton
} from '@chakra-ui/react';
import { FaMusic } from "react-icons/fa"

import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';

import { ColorModeSwitcher } from './ColorModeSwitcher';
import client from './client';

export const App = () => {
  const [posts, setPosts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setIsFetching(true);
    const variables = {
      skip: 0,
      first: 5
    };
    const fetchData = async () => {
      try {
        const result = await client.request(query, variables);
        setPosts(result.posts);
        setIsFetching(false);
      } catch (error) {
        console.error(JSON.stringify(error, undefined, 2));
        setIsFetching(false);
      }
    };

    fetchData();
  }, []);

  const [isLargerThan560] = useMediaQuery('(min-width: 560px)');

  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl" maxWidth="680px" margin="auto">
        <Grid minH="100vh" p={6} pt={12} pb={12}>
          <Flex alignItems="center" marginBottom={8}>
            <Box paddingRight={2}>
              <Heading size="xl">Unmastered.</Heading>
            </Box>
            <Spacer />
            <Stack spacing={1} direction="row" align="center">
              <IconButton aria-label="Listen to my music" 
              size="md"
              fontSize="lg"
              variant="ghost"
              color="current"
              onClick={() => { 
                window.open('https://soundcloud.com/nickybutter', '_blank');
              }}
              icon={<Icon as={FaMusic} />} />
              <ColorModeSwitcher justifySelf="flex-end" />
            </Stack>
          </Flex>
          <Flex marginBottom={16}>
            <Avatar
              src="https://avatars0.githubusercontent.com/u/1946238?s=460&u=df07a846a0a82f7463a68c69e10466518bdd382d&v=4"
              size="lg"
              name="nikima"
            />
            <Box ml="3">
              <Text>
                Personal blog by{' '}
                <Link color="green.500" href="https://nikonenik.com">
                  nikima
                </Link>
              </Text>
              <Text>I try to explain with words.</Text>
            </Box>
          </Flex>
          <Stack spacing={16}>
            {isFetching ? (
              <Stack>
                <SkeletonText noOfLines={2} marginBottom={2} />
                <SkeletonText noOfLines={3} spacing="2" />
              </Stack>
            ) : (
              posts.map(
                ({
                  id,
                  title,
                  // image,
                  preview,
                  createdAt
                }: {
                  id: number;
                  title: string;
                  // image: any;
                  preview: string;
                  createdAt: string;
                }) => {
                  return (
                    <Box key={id}>
                      <article>
                        <header>
                          <Heading fontSize="xx-large" marginBottom={2}>
                            <Link color="green.500">{title}</Link>
                            <Text fontSize="md" fontWeight="400" marginTop={3}>
                              {format(parseISO(createdAt), 'PPP')}
                            </Text>
                          </Heading>
                        </header>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: preview
                          }}
                          css={{ whiteSpace: `pre-line` }}
                        />
                      </article>
                    </Box>
                  );
                }
              )
            )}
          </Stack>
          <Flex
            mt={20}
            direction={isLargerThan560 ? 'initial' : 'column'}
            justify="space-between">
            <Box>
              <HStack>
                <Link
                  color="green.500"
                  href={`https://www.instagram.com/nikima`}>
                  instagram
                </Link>
                <Link
                  color="green.500"
                  href={`https://soundcloud.com/nickybutter`}>
                  soundcloud
                </Link>
                <Link
                  color="green.500"
                  href={`https://twitter.com/nickybutterLA`}>
                  twitter
                </Link>
                {/* https://soundcloud.com/nickybutter */}
              </HStack>
            </Box>
            <Box>Come On, it&#39;s {format(new Date(), `yyyy`)}.</Box>
          </Flex>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

const query = `
  query posts($first: IntType!, $skip: IntType!) {
    meta: _allPostsMeta {
      count
    }
    posts: allPosts(orderBy: _createdAt_DESC, first: $first, skip: $skip) {
      id
      title
      preview
      createdAt
    }
  }
`;
